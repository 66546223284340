<template>
  <div class="live-page">
    <iframe :src="iframeSrc" allowfullscreen="true"></iframe>
  </div>
</template>

<script>
import * as Utils from '@/utils';

export default {
  name: 'live',
  data() {
    return {
      iframeSrc: ''
    };
  },
  created() {
    const userInfo = Utils.getLocalStorage('LoginUser');
    if (userInfo) {
      const url = `https://live.vhall.com/v3/lives/embedclient/watch/${this.$route.query.webinarId}?email=${userInfo.mobile}@vhall.com&nickname=${userInfo.nickname}`;
      this.iframeSrc = url;
    }
    else {
      const url = `https://live.vhall.com/v3/lives/embedclient/watch/${this.$route.query.webinarId}?email=@vhall.com&nickname=`;
      this.iframeSrc = url;
    }
  }
};
</script>

<style lang="scss" scoped>
.live-page {
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
